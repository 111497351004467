import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { format } from "date-fns"
import { useBottomScrollListener } from "react-bottom-scroll-listener"

import Layout from "../components/layout"
import Header from "../components/header"

const Info = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)

  const [hovered1, setHovered1] = useState(false)
  const toggleHover1 = () => setHovered1(!hovered1)

  const [hovered2, setHovered2] = useState(false)
  const toggleHover2 = () => setHovered2(!hovered2)

  const [hovered3, setHovered3] = useState(false)
  const toggleHover3 = () => setHovered3(!hovered3)

  const [hovered4, setHovered4] = useState(false)
  const toggleHover4 = () => setHovered4(!hovered4)

  const [hovered5, setHovered5] = useState(false)
  const toggleHover5 = () => setHovered5(!hovered5)

  const [hovered6, setHovered6] = useState(false)
  const toggleHover6 = () => setHovered6(!hovered6)

  const [hovered7, setHovered7] = useState(false)
  const toggleHover7 = () => setHovered7(!hovered7)

  const [hovered8, setHovered8] = useState(false)
  const toggleHover8 = () => setHovered8(!hovered8)

  const [hovered9, setHovered9] = useState(false)
  const toggleHover9 = () => setHovered9(!hovered9)

  const [hovered10, setHovered10] = useState(false)
  const toggleHover10 = () => setHovered10(!hovered10)

  const [atBottom, setAtBottom] = useState(false)

  const today = new Date()
  const formattedDate = format(today, "PPP, h:m aaaa")

  let bottom = 0

  const handleOnDocumentBottom = () => {
    bottom += 1

    if (bottom > 3) {
      setAtBottom(true)
    }
  }

  useBottomScrollListener(handleOnDocumentBottom)

  console.log({data});
  return (
    <Layout>
      <Header />
      <div className="info">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>Info</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>Developer: Ramon Munoz</h2>
            </div>
            <div className="offset-lg-2 col-lg-2">
              <h2>Font: El Centro</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row">
            <div className="col-md-7">
              <h1>Roberto...</h1>
              <div className="content-info">
                <button
                  className="btn btn-link info-1"
                  onMouseEnter={toggleHover1}
                  onMouseLeave={toggleHover1}
                >
                  writes sometimes
                </button>
                <img
                  className={`info-1-image ${hovered1 ? "active" : ""}`}
                  src="https://res.cloudinary.com/rwh/image/upload/v1607370111/rgrq/static/info/01_Writes_Sometimes_upbmnv.jpg"
                  alt=""
                />
                <button
                  className="btn btn-link info-2"
                  onMouseEnter={toggleHover2}
                  onMouseLeave={toggleHover2}
                >
                  is Mexican
                </button>
                <img
                  className={`info-2-image ${hovered2 ? "active" : ""}`}
                  src="https://res.cloudinary.com/rwh/image/upload/v1607370112/rgrq/static/info/07_is_Mexican_temlyq.jpg"
                  alt=""
                />
                <button
                  className="btn btn-link info-3"
                  onMouseEnter={toggleHover3}
                  onMouseLeave={toggleHover3}
                >
                  plays soccer
                </button>
                <img
                  className={`info-3-image ${hovered3 ? "active" : ""}`}
                  src="https://res.cloudinary.com/rwh/image/upload/v1607370112/rgrq/static/info/05_Plays_Soccer_qvdkjo.jpg"
                  alt=""
                />
                <button
                  className="btn btn-link info-4"
                  onMouseEnter={toggleHover4}
                  onMouseLeave={toggleHover4}
                >
                  favorite soccer team
                </button>
                <video
                  className={`info-4-image ${hovered4 ? "active" : ""}`}
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607370115/rgrq/static/info/10_Favorite_Soccer_team_doamig.mp4"
                    type="video/mp4"
                  />
                </video>
                <button
                  className="btn btn-link info-5"
                  onMouseEnter={toggleHover5}
                  onMouseLeave={toggleHover5}
                >
                  favorite baseball team
                </button>
                <video
                  className={`info-5-image ${hovered5 ? "active" : ""}`}
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607370117/rgrq/static/info/09_favorite_baseball_team_vwedur.mp4"
                    type="video/mp4"
                  />
                </video>
                <button
                  className="btn btn-link info-6"
                  onMouseEnter={toggleHover6}
                  onMouseLeave={toggleHover6}
                >
                  takes pictures
                </button>
                <img
                  className={`info-6-image ${hovered6 ? "active" : ""}`}
                  src="https://res.cloudinary.com/rwh/image/upload/v1607370113/rgrq/static/info/06_Takes_Pictures_rhwwtc.jpg"
                  alt=""
                />

                <button
                  className="btn btn-link info-7"
                  onMouseEnter={toggleHover7}
                  onMouseLeave={toggleHover7}
                >
                  is an Immigrant
                </button>
                <img
                  className={`info-7-image ${hovered7 ? "active" : ""}`}
                  src="https://res.cloudinary.com/rwh/image/upload/v1607370112/rgrq/static/info/03_Is_An_Immigrant_vo81c6.jpg"
                  alt=""
                />

                <button
                  className="btn btn-link info-8"
                  onMouseEnter={toggleHover8}
                  onMouseLeave={toggleHover8}
                >
                  is an ArtCenter Graduate
                </button>
                <img
                  className={`info-8-image ${hovered8 ? "active" : ""}`}
                  src="https://res.cloudinary.com/rwh/image/upload/v1607370112/rgrq/static/info/02_Is_An_ArtCenter_Graduate_slcffe.jpg"
                  alt=""
                />

                <button
                  className="btn btn-link info-9"
                  onMouseEnter={toggleHover9}
                  onMouseLeave={toggleHover9}
                >
                  is a homie
                </button>
                <img
                  className={`info-9-image ${hovered9 ? "active" : ""}`}
                  src="https://res.cloudinary.com/rwh/image/upload/v1607370112/rgrq/static/info/04_s_a_homie_pplblj.jpg"
                  alt=""
                />

                <button
                  className="btn btn-link info-10"
                  onMouseEnter={toggleHover10}
                  onMouseLeave={toggleHover10}
                >
                  enjoys music
                </button>
                <video
                  className={`info-10-image ${hovered10 ? "active" : ""}`}
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="https://res.cloudinary.com/rwh/video/upload/v1607370115/rgrq/static/info/08_enjoys_music_m3kz8e.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <img
                className="img-fluid d-block m-auto"
                src="https://res.cloudinary.com/rwh/image/upload/v1607370112/rgrq/static/info/00_Main_Page_fnaxdo.jpg"
                alt=""
              />
              <div className="row">
                <div className="col-md-6">
                  <p className="time">Current time: {formattedDate}</p>
                </div>
                <div className="col-md-6">
                  <p className="time text-right">Photo by Ramon Munoz</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info-footer">
          <div className="container-fluid">
            <div className="row justify-content-between align-items-end">
              <div className="col-md-2">
                <h2>
                  <a
                    href={data.allFile.edges[0].node.publicURL}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Resume
                  </a>
                </h2>
                <h2>
                  <a
                    href="https://www.instagram.com/rgrq.xyz/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Instagram ↗
                  </a>
                </h2>
              </div>
              <div className="col-md-2">
                <h2 className="url text-center">
                  <Link to="/">rg-rq.com</Link>
                </h2>
              </div>
              <div className="col-md-2">
                <h2>
                  <a
                    href="https://www.linkedin.com/in/rgrq/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Linkedin
                  </a>
                </h2>
                <h2>↓ Los Angeles</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`info-extra ${atBottom ? "active" : ""}`}>
        <div className="container-fluid">
          <div className="row align-items-end justify-content-between">
            <div className="col-2">
              <h1>Ramon Was Here</h1>
            </div>
            <div className="col-3">
              <video
                className="info-extra-video"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src="https://res.cloudinary.com/rwh/video/upload/v1607370113/rgrq/static/info/11_Dance_mdtsmy.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Info
